import React, { useRef, useState, useCallback } from "react";
import { Row, Col } from "reactstrap";
import HalfTone from "assets/img/bgs/halftone.png";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import ContactSummary from "components/Summaries/ContactSummary";
import ContactForm from "components/Summaries/ContactForm";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";

var h2Style = {
  backgroundImage: "url(" + HalfTone + ")",
  marginTop: "-98px",
  zIndex: "2",
  border: "1px solid black",
};
var galleryStyle = {
  backgroundImage: "url(" + HalfTone + ")",
  maxWidth: "100vw",
};

function GalleryView() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    console.log(photo.src);
    let indexModifier = 0;

    if(photo.src.includes("ek")){
      indexModifier = 11;
    }

    if(photo.src.includes("ak")){
      indexModifier = 32;
    }

    if(photo.src.includes("ok")){
      indexModifier = 46;
    }
    console.log(indexModifier);

    setCurrentImage(index + indexModifier);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const myRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const imagesOm = [
    { src: require("../assets/img/weddingOm/om1.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om2.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om3.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om4.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om5.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om6.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om7.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om8.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om9.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om10.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOm/om11.jpg"), width: 3, height: 2 },
  ];

  const imagesEk = [
    { src: require("../assets/img/weddingEk/ek1.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek2.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek3.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek4.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek5.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek6.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek7.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek8.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek9.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek10.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek11.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek12.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek13.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek14.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek15.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek16.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek17.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek18.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek19.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek20.webp"), width: 3, height: 2 },
    { src: require("../assets/img/weddingEk/ek21.webp"), width: 3, height: 2 },
  ];

  const imagesAk = [
    { src: require("../assets/img/weddingAk/ak1.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak2.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak3.jpg"), width: 2, height: 3 },
    { src: require("../assets/img/weddingAk/ak4.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak5.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak6.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak7.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak8.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak9.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak10.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak11.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak12.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak13.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingAk/ak14.jpg"), width: 3, height: 2 },
  ];

  const imagesOk = [
    { src: require("../assets/img/weddingOk/ok8.JPG"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOk/ok1.jpg"), width: 2, height: 3 },
    { src: require("../assets/img/weddingOk/ok2.jpg"), width: 2, height: 3 },
    { src: require("../assets/img/weddingOk/ok3.jpg"), width: 2, height: 3 },
    { src: require("../assets/img/weddingOk/ok4.jpg"), width: 3, height: 2 },
    { src: require("../assets/img/weddingOk/ok5.JPG"), width: 2, height: 3 },
    { src: require("../assets/img/weddingOk/ok6.JPG"), width: 2, height: 3 },
    { src: require("../assets/img/weddingOk/ok7.JPG"), width: 2, height: 3 },
    { src: require("../assets/img/weddingOk/ok8.JPG"), width: 3, height: 2 },
  ];

  return (
    <>
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={[...imagesOm, ...imagesEk, ...imagesAk, ...imagesOk].map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <IndexNavbar exeScroll={scrollToRef} myRefe={myRef} />
      <ProfilePageHeader />
      <div className="section profile-content sec-colour text-center pb-0">
        <Row className="section pt-0 mt-0 pb-0 mx-auto" style={galleryStyle}>
          <p
            className="title font-weight-normal py-4 w-75 mx-auto h2"
            style={h2Style}
          >
            Nasze Pary
          </p>
          <Col
            lg="7"
            sm="12"
            className="mx-auto text-center thrd-colour"
            style={{ paddingBottom: "30px" }}
          >
            <p className="h3 font-weight-bold text-dark"> Ola i Mateusz </p>
          </Col>
          <Row className="px-5">
            <Gallery photos={imagesOm} onClick={openLightbox} />
          </Row>
          <Col className="ml-auto text-right" md="8">
            <p className="font-weight-bold text-dark">Fot. Piotr Gardyła</p>
          </Col>
        </Row>
        <Row className="section pt-0 mt-0 pb-0 mx-auto" style={galleryStyle}>
          <Col
            lg="7"
            sm="12"
            className="mx-auto text-center thrd-colour"
            style={{ paddingBottom: "30px" }}
          >
            <p className="h3 font-weight-bold text-dark"> Ewa i Krzysztof </p>
          </Col>
          <Row className="px-5">
            <Gallery photos={imagesEk} onClick={openLightbox} />
          </Row>
          <Col className="ml-auto text-right" md="8">
            <p className="font-weight-bold text-dark">Fot. Karol Chaba</p>
          </Col>
        </Row>
        <Row className="section pt-0 mt-0 pb-0 mx-auto" style={galleryStyle}>
          <Col
            lg="7"
            sm="12"
            className="mx-auto text-center thrd-colour"
            style={{ paddingBottom: "30px" }}
          >
            <p className="h3 font-weight-bold text-dark"> Anna i Kamil </p>
          </Col>
          <Row className="px-5">
            <Gallery photos={imagesAk} onClick={openLightbox} />
          </Row>
          <Col className="ml-auto text-right" md="8">
            <p className="font-weight-bold text-dark">Fot. Kamil Jargot</p>
          </Col>
        </Row>
        <Row className="section pt-0 mt-0 pb-0 mx-auto" style={galleryStyle}>
          <Col
            lg="7"
            sm="12"
            className="mx-auto text-center thrd-colour"
            style={{ paddingBottom: "30px" }}
          >
            <p className="h3 font-weight-bold text-dark">
              {" "}
              Aleksandra i Karol{" "}
            </p>
          </Col>
          <Row className="px-5">
            <Gallery photos={imagesOk} onClick={openLightbox} />
          </Row>
          <Col className="ml-auto text-right" md="8">
            <p className="font-weight-bold text-dark">Fot. Janko Fotograf</p>
          </Col>
        </Row>
      </div>

      <ContactSummary refProp={myRef} />
      <ContactForm />
      <DemoFooter />
    </>
  );
}

export default GalleryView;
